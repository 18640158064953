/* .list_after {
  left: 0;
  right: 0;
  bottom: -15px;
  position: absolute;
  background-color: #131d34;
  display: block;
  height: 15px;
  width: 100%;
  transition: all 1.5s;
}
.list_before {
  left: 0;
  right: 0;
  top: -15px;
  position: absolute;
  background-color: #131d34;
  display: block;
  height: 15px;
  width: 100%;
  transition: all 1.5s;
}

.list_item {
  border: 1px solid transparent;
  border-right: none;
}

.list_item:focus {
  border: 1px solid #131d34;
  border-right: none;
}

.list_before::before {
  content: '';
  height: 15px;
  background-color: white;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  border-bottom-right-radius: 15px;
  transition: all 1.5s;
}

.list_after::after {
  content: '';
  height: 15px;
  background-color: white;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  border-top-right-radius: 15px;
  transition: all 1.5s;
} */



#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}